<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">{{title}}</h4></v-toolbar-title>
      </v-toolbar>
      <v-simple-table dense class="mt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center text-uppercase">ID</th>
            <th class="text-center text-uppercase">Action</th>
            <th class="text-center text-uppercase">Cheque</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in data_items" :key="item.id">
            <td class="text-center">
              {{item.id}}
            </td>
            <td class="text-center">
              <v-icon v-if="!is_deleting" class="mr-2" color="error"
                      @click="delete_uploaded_chequee(item)">
                {{ icons.mdiDelete }}
              </v-icon>
              <v-progress-circular color="info" indeterminate
                                   v-if="is_deleting"></v-progress-circular>
            </td>
            <td>
              <v-img :src="item.image" width="80%" cover></v-img>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiDelete, mdiCheck, mdiCloseCircleOutline} from '@mdi/js'
  import moment from 'moment'
  import {mapActions, mapGetters} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    components: {
      snackBarDialog,
    },
    props: {
      title: String,
      data_items: Array,
    },
    setup() {
      return {
        icons: {
          mdiDelete,
          mdiCheck,
          mdiCloseCircleOutline,
        },
        is_deleting: false,
      }
    },
    computed: {
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['position']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('uploaded_cheque', ['delete_uploaded_cheque']),
      delete_uploaded_chequee(item) {
        this.is_deleting = true
        this.delete_uploaded_cheque({
          id: item.id,
        })
          .then(response => {
            var color = 'error'
            if (response.status === 200) {
              color = 'success'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.is_deleting = false
            this.$emit('data', 0)
          })
          .catch(error => {
            console.log(error)
          })
      },
    },
  }
</script>
